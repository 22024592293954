import { useState } from 'react'
import cn from '@utils/helpers/cn'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'
import Icon from '@components/ui/Icon'
import { storyblokEditable } from '@storyblok/react'

const FooterComponent = ({ blok }) => {
  const [active, setActive] = useState(false)
  return (
    <div
      className={cn('flex w-full flex-col', blok.class)}
      {...storyblokEditable(blok)}
    >
      <div
        className="mb-1 mt-2.5 flex items-center justify-between border-b border-gray-600 pb-2 font-roboto text-base font-normal uppercase lg:pointer-events-none lg:border-red-700"
        onClick={() => setActive(!active)}
      >
        {blok.title}
        <Icon
          icon="icon-chevron-down"
          className={cn('pointer-events-none pr-2 text-white lg:hidden', {
            '-translate-x-2 rotate-180': active,
          })}
        />
      </div>
      <div
        className={cn('hidden py-5 lg:block lg:py-2', {
          block: active,
        })}
      >
        <div className={cn(blok.blokClass || '')}>
          {blok.blocks
            ?.filter(
              (b) =>
                (b.hasOwnProperty('showBlock') && b.showBlock) ||
                !b.hasOwnProperty('showBlock'),
            )
            .map((nestedBlok, index) => (
              <StoryblokComponent
                index={index}
                blok={nestedBlok}
                key={nestedBlok._uid}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default FooterComponent
